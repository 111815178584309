@import url('./normalize.css');

body {
  max-width: 430px;
  width: 100%;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

.box-hide-scroll {
  scrollbar-width: none;
}

.box-hide-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
